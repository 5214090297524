import { Link } from 'react-router-dom';
import * as ROUTES from '../../routes';

const TermsOfService = () => (
  <div className="tos">
    <h2>Terms of Service</h2>

    <div className="description">
      <p>Posted and effective as of: August 17, 2018</p>

      <p>
        We're so happy that you've chosen us to help you manage your photos! This is our Terms of
        Service — it outlines the rules of using Group Photo to ensure safe use for you and all of
        our other users.
      </p>

      <p>
        The following Terms of Service ("Terms") cover your use and access to Group Photo Software
        Inc.'s (“Our”, "We", "Group Photo") services, client software, and websites ("Services"). By
        using our Services, you are agreeing to be bound by these Terms, including those found in
        our Privacy, Acceptable Use, and DMCA Policies. We may update these Terms from time to time,
        and will post those updated documents on our website. Substantive changes to our Terms of
        Service may be published in a way that requires our users to affirmatively assent to those
        Terms prior to continuing use of our Services. Your continued use of our Services will
        constitute binding acceptance of those changes. If any modification is unacceptable to you,
        your only recourse is to not use Group Photo's Services. You may also contact us at
        support@groupphoto.com to voice your disapproval. We want to create fair terms, so we
        genuinely welcome your feedback.
      </p>
    </div>

    <div className="description">
      <h5>1. Group Photo's Services</h5>
      <ol type="a">
        <li>
          We provide a number of Internet-based services through our websites and through our
          desktop and mobile applications, including media backup, media sharing and curating, media
          publishing, and chat. These Services are provided “as-is”, and we reserve the right to
          make modifications to the Services we provide from time to time.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>2. Privacy Policy</h5>
      <ol type="a">
        <li>
          We respect the privacy of our users. Please refer to the our{' '}
          <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link> which explains how we collect, use,
          and disclose information that pertains to your privacy.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>3. Registration</h5>
      <ol type="a">
        <li>You need to be at least 13 years old to register for and use the Service.</li>
        <li>
          If you are a user who signs up for the Service, you will create a personalized account
          which includes a unique username and a password to access the Service. You agree to notify
          us immediately of any unauthorized use of your password and/or account. Resetting your
          password will also log out all sessions of your account. The Company will not be
          responsible for any liabilities, losses, or damages arising out of the unauthorized use of
          your member name, password and/or account.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>4. Your License to Use Our Services</h5>
      <ol type="a">
        <li>
          Upon download and installation of our client software, a limited, non-exclusive,
          non-transferable, revocable license to use that software is granted, solely for the
          purpose of accessing our Services.
        </li>
        <li>
          Our Services are built on certain technology and intellectual property that we either own
          or license, and your license to use our Services does not grant you any rights to, title
          to, or interest in that technology or property. You agree to under no circumstance attempt
          to reverse engineer or decompile any aspect of our Services, nor collect or use
          information contained in our Services, nor make use of any of our trademarks or other
          intellectual property for reasons not expressly allowed for in these Terms.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>5. Intellectual Property</h5>
      <ol type="a">
        <li>
          You acknowledge and agree that we and our licensors retain ownership of all intellectual
          property rights of any kind related to the Service, including applicable copyrights,
          trademarks and other proprietary rights. Other product and company names that are
          mentioned on the Service may be trademarks of their respective owners. We reserve all
          rights that are not expressly granted to you under these Terms of Use.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>6. Account Subscriptions</h5>
      <ol type="a">
        <li>
          In order to make use of our Services, you must subscribe to either a Free Plan or a
          Standard Plan. Users on a Free Plan subscription are granted access to our Services and a
          limited amount of storage at no cost. Users on our Standard Plan have access to the same
          Services and an additional amount of storage, but under the terms of the Standard Plan
          subscription agree to be charged by Group Photo either monthly or annually.
        </li>
        <li>
          Users on a Standard Plan subscription are required to maintain a current and valid payment
          method at all times. Should that payment method fail for any reason at the end of a
          billing period, we reserve the right to limit access to or restrict usage of the account
          until the outstanding balance is paid. We additionally reserve the right to terminate a
          Standard Plan account, and permanently delete its contents, if its subscription status
          remains unpaid for 12 months.
        </li>
        <li>
          Should you subscribe to our Standard Plan, you also agree to the automatic upgrade of that
          Plan to the next tier of storage should your account exceed that Plan's limit. The terms
          of this Automatic Upgrade, including storage limits and pricing for the next tier, are
          defined through the Standard Plan's signup process.
        </li>
        <li>
          You can downgrade your Standard Plan account to a Free Plan account at any time; however,
          you are solely responsible for the removal of media occupying storage above your Plan's
          limit. Our support staff are available to help in this process but are unable to affect a
          change to your monthly or annual subscription amounts until your account's used storage
          falls under the Free Plan's threshold.
        </li>
        <li>
          Any Plan downgrades or cancellations initiated by you are not eligible for a pro-rata
          refund based on the number of months or days remaining on the term. Account cancellations
          initiated by us will only eligible for such refund at our sole discretion. However, if you
          feel that you're entitled to a refund, please contact us at support@groupphoto.com and we
          do our best to arrange for a fair compromise. Any refunds issued are done so at our sole
          discretion.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>7. Your Personal Data, and Data Shared by Others</h5>
      <ol type="a">
        <li>
          Rights to all of the photos, videos, and other media content files that you choose to
          upload to our servers are retained exclusively and in perpetuity by the owner of the
          account those files are uploaded into. Our Terms do not give us any rights to your media
          files except the limited rights we require in order to enable us to offer our Services to
          you and your social network.
        </li>
        <li>
          Your use of our Services requires your extension of those limited rights to us, and your
          use of our Services represents agreement to that extension and the rest of the Terms
          presented herein. For more information on what information we collect and how we use the
          data we collect or store, please review our Privacy Policy.
        </li>
        <li>
          We uphold the intellectual property rights of others and respond to alleged copyright
          infringement via the means defined in our DMCA Policy. Images, videos, and audio files you
          did not create yourself or otherwise own rights to legally belong to someone else. Please
          respect those rights. Our Services are not provided as a means to share copyrighted
          material; any users of our Services determined to show a pattern of copyright infringement
          will be deemed in violation of these Terms. We reserve the right to delete content or
          disable accounts for that reason without warning and at our sole discretion.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>8. Acceptable Use</h5>
      <ol type="a">
        <li>
          In addition to the provisions in these Terms defining limits on media you do not own or
          have rights to, these Terms also define what we consider “Acceptable Use” of our Services,
          and the actions we reserve the right to take against users found to be in violation of
          those Terms. Your use of our Services represents agreement to the Terms presented herein,
          and also in our Acceptable Use Policy.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>9. Use Restrictions</h5>
      <ol type="a">
        <li>
          Your permission to use the Site is conditioned upon the following use, posting and conduct
          restrictions. You agree that you will not under any circumstances:
          <ol type="i">
            <li>Collect or harvest any personal data of any user of the Site or the Service</li>
            <li>
              distribute any part or parts of the Site or the Service without our explicit written
              permission (we grant the operators of public search engines permission to use spiders
              to copy materials from the site for the sole purpose of creating publicly-available
              searchable indices but retain the right to revoke this permission at any time on a
              general or specific basis);
            </li>
            <li>
              use the Service for any unlawful purpose or for the promotion of illegal activities;
            </li>
            <li>attempt to, or harass, abuse or harm another person or group;</li>
            <li>use another user's account without permission;</li>
            <li>intentionally allow another user to access your account;</li>
            <li>provide false or inaccurate information when registering an account;</li>
            <li>interfere or attempt to interfere with the proper functioning of the Service;</li>
            <li>
              make any automated use of the Site, the Service or the related systems, or take any
              action that we deem to impose or to potentially impose an unreasonable or
              disproportionately large load on our servers or network infrastructure;
            </li>
            <li>
              bypass any robot exclusion headers or other measures we take to restrict access to the
              Service, or use any software, technology, or device to scrape, spider, or crawl the
              Service or harvest or manipulate data;
            </li>
            <li>
              use multiple free plan accounts in an attempt to bypass having to pay for the standard
              plan;
            </li>
            <li>
              circumvent, disable or otherwise interfere with any security-related features of the
              Service or features that prevent or restrict use or copying of content, or enforce
              limitations on use of the Service or the content accessible via the Service; or
            </li>
            <li>
              publish or link to malicious content of any sort, including that intended to damage or
              disrupt another user's browser or computer.
            </li>
          </ol>
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>10. Posting and Content Restrictions</h5>
      <ol type="a">
        <li>
          When you create your own personalized account, you may be able to provide photos, videos,
          audio, and chat messages (“User Content”) to the Service. You are solely responsible for
          the User Content that you post, upload, link to or otherwise make available via the
          Service. You agree that we are only acting as a passive conduit for your online
          distribution and publication of your User Content. The Company, however, reserves the
          right to remove any User Content from the Service at its sole discretion. We grant you
          permission to use and access the Service, subject to the following express conditions
          surrounding User Content. You agree that failure to adhere to any of these conditions
          constitutes a material breach of these Terms.
        </li>
        <li>
          By transmitting and submitting any User Content while using the Service, you agree as
          follows:
          <ol type="i">
            <li>
              You are solely responsible for your account and the activity that occurs while signed
              in to or while using your account;
            </li>
            <li>You will not post information that is malicious, libelous, false or inaccurate;</li>
            <li>
              You will not post any information that is abusive, threatening, obscene, defamatory,
              libelous, or racially, sexually, religiously, or otherwise objectionable and
              offensive;
            </li>
            <li>
              You will not submit content that is copyrighted or subject to third party proprietary
              rights, including privacy, publicity, trade secret, or others, unless you are the
              owner of such rights or have the appropriate permission from their rightful owner to
              specifically submit such content; and
            </li>
            <li>
              You hereby agree that we have the right to determine whether your User Content
              submissions are appropriate and comply with these Terms of Service, remove any and/or
              all of your submissions, and terminate your account with or without prior notice.
            </li>
          </ol>
        </li>
        <li>
          You understand and agree that any liability, loss or damage that occurs as a result of the
          use of any User Content that you make available or access through your use of the Service
          is solely your responsibility. The Site is not responsible for any public display or
          misuse of your User Content.
        </li>
        <li>
          The Site does not, and cannot, pre-screen or monitor all User Content. However, at our
          discretion, we, or technology we employ, may remove user content that is in breach of
          these Terms.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>11. Online Content Disclaimer</h5>
      <ol type="a">
        <li>
          Opinions, advice, statements, offers, or other information or content made available
          through the Service, but not directly by the Site, are those of their respective authors,
          and should not necessarily be relied upon. Such authors are solely responsible for such
          content.
        </li>
        <li>
          We do not guarantee the accuracy, completeness, or usefulness of any information on the
          Site or the Service nor do we adopt nor endorse, nor are we responsible for, the accuracy
          or reliability of any opinion, advice, or statement made by other parties. We take no
          responsibility and assume no liability for any User Content that you or any other user or
          third party posts or sends via the Service. Under no circumstances will we be responsible
          for any loss or damage resulting from anyone's reliance on information or other content
          posted on the Service, or transmitted to users.
        </li>
        <li>
          Though we strive to enforce these Terms of Service, you may be exposed to User Content
          that is inaccurate or objectionable when you use or access the Site or the Service. We
          reserve the right, but have no obligation, to monitor the materials posted in the public
          areas of the Site or the Service or to limit or deny a user's access to the Service or
          take other appropriate action if a user violates these Terms of Use or engages in any
          activity that violates the rights of any person or entity or which we deem unlawful,
          offensive, abusive, harmful or malicious. [E-mails sent between you and other participants
          that are not readily accessible to the general public will be treated by us as private to
          the extent required by applicable law.] The Company shall have the right to remove any
          material that in its sole opinion violates, or is alleged to violate, the law or this
          agreement or which might be offensive, or that might violate the rights, harm, or threaten
          the safety of users or others. Unauthorized use may result in criminal and/or civil
          prosecution under Federal, State/Provincial and local law. If you become aware of a misuse
          of our Service or violation of these Terms of Service, please contact us at
          support@groupphoto.com.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>12. Termination</h5>
      <ol type="a">
        <li>
          You may terminate your Group Photo account at any time by submitting an email request to
          support@groupphoto.com.
        </li>
        <li>
          Group Photo reserves the right to terminate your account at any time, without prior
          notice, should you breach any terms of this service agreement.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>13. Password-Restricted Aspects of Our Service</h5>
      <ol type="a">
        <li>
          Your personal media is protected by the password you configure and your text message phone
          account when activating your Group Photo account for the first time. Anyone in possession
          of your login password, or who is able to correctly guess your login password, and who has
          access to your phone or your phone's account, will have full and complete access to your
          personal media. For that reason, it is essential that you both choose a password that is
          sufficiently complex and keep that password safe. You should also contact your telephone
          supplier and place a password on your telephone account to prevent unauthorized persons
          from transferring your phone account to a different SIM card. Furthermore, through use of
          our Services, you agree that you are entirely responsible for maintaining the
          confidentiality of your password, and agree to notify us if the password is lost, stolen,
          disclosed to an unauthorized third party, or otherwise may have been compromised. You
          agree to reset your password at https://groupphoto.com/forgot-password immediately, in
          such an event. You agree that you are entirely responsible for any and all activities that
          occur under your account, including any fees that may be incurred under your
          password-protected account, whether or not you are the individual who undertakes such
          activities. You agree to immediately notify us of any unauthorized use of your account or
          any other breach of security in relation to your password or the Website that is known to
          you. Please notify us at support@grouphoto.com.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>14. General Terms</h5>
      <ol type="a">
        <li>
          If any part of this Terms of Use agreement is held or found to be invalid or
          unenforceable, that portion of the agreement will be construed as to be consistent with
          applicable law while the remaining portions of the agreement will remain in full force and
          effect. Any failure on our part to enforce any provision of this agreement will not be
          considered a waiver of our right to enforce such provision. Our rights under this
          agreement survive any transfer or termination of this agreement.
        </li>
        <li>
          You agree that any cause of action related to or arising out of your relationship with the
          Company must commence within ONE year after the cause of action accrues. Otherwise, such
          cause of action is permanently barred.
        </li>
        <li>
          These Terms of Use and your use of the Site are governed by the federal laws of Canada and
          the laws of the Province of British Columbia, without regard to conflict of law
          provisions.
        </li>
        <li>
          We may assign or delegate these Terms of Service and/or our Privacy Policy, in whole or in
          part, to any person or entity at any time with or without your consent. You may not assign
          or delegate any rights or obligations under the Terms of Service or Privacy Policy without
          our prior written consent, and any unauthorized assignment or delegation by you is void.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>15. Arbitration and Jurisdiction</h5>
      <ol type="a">
        <li>
          By using Group Photo's Service, you agree that the Commercial Arbitration Act, applicable
          Canadian federal law, and the laws of the province of British Columbia, without regard to
          principles of conflict of laws, will govern these Conditions of Use and any sort that
          might arise between you and Group Photo.
        </li>
      </ol>
    </div>
  </div>
);

export default TermsOfService;
