import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRightWhite, Users } from '../Icons';
import { clsx } from '../lib/helpers';

import HomepagePhoneFamily from '../assets/images/homepage/homepage-phone-family.png';
import HomepagePhoneFamilyPost from '../assets/images/homepage/homepage-phone-family-post.png';
import HomepagePhoneBackup from '../assets/images/homepage/homepage-phone-backup.png';
import HomepagePhoneBackupPost from '../assets/images/homepage/homepage-phone-backup-post.png';

//marketing - how it works
const HowItWorks: React.FC<{
  data?: any;
}> = ({ data }) => (
  <div className="section-how-it-works">
    <div className="marketing-container">
      <div className="content">
        {data &&
          data.map(
            (
              item: {
                icon?: string;
                title?: string;
                description?: string;
                buttonURL?: string | undefined;
                image?: string;
                imageName?: string;
              },
              i: number
            ) => (
              <div className="item" key={i}>
                {item.icon && item.icon === 'users' && <Users />}
                {item.icon && item.icon === 'user-camera' && <Users />}
                <h3 className="title">{item.title}</h3>
                <p className="description">{item.description}</p>
                <Link
                  to={item.buttonURL ? item.buttonURL : ''}
                  className={clsx('btn btn-blue-outline', {
                    'btn-inverted': i === 1,
                  })}
                >
                  How it works <ArrowRightWhite />
                </Link>

                {/* {item.image && <img src={item.image} className="image" alt={item.imageName} />} */}
                {i === 0 && (
                  <>
                    <img src={HomepagePhoneFamily} className="image" alt="" />
                    <img src={HomepagePhoneFamilyPost} className="family-post" alt="" />
                  </>
                )}
                {i === 1 && (
                  <>
                    <img src={HomepagePhoneBackup} className="image" alt="" />
                    <img src={HomepagePhoneBackupPost} className="backup-post" alt="" />
                  </>
                )}
              </div>
            )
          )}
      </div>
    </div>
  </div>
);

export default React.memo(HowItWorks);
