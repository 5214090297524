import React from 'react';

import { AudioPlayer, Camera, Television, VideoRecorder } from '../Icons';

//marketing - comparison
const Comparison: React.FC = () => {
  return (
    <>
      <div className="comparison comparison-desktop">
        <div className="comparison-column">
          <div className="card">
            <h2>
              2<span>GB</span> <span>Plan</span>
            </h2>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>8 MP Camera</h4>
            </div>
            <div className="card-body">
              <h5>714 Pictures</h5>
              <p>2.4MB JPG 100%</p>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>HD Video</h4>
            </div>
            <div className="card-body">
              <h5>33 minutes</h5>
              <p>720p Videos</p>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>4K Video</h4>
            </div>
            <div className="card-body">
              <h5>5 minutes</h5>
              <p>480p Videos</p>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>M4A Audio</h4>
            </div>
            <div className="card-body">
              <h5>
                66 hours
                <br />
                Voice Recording
              </h5>
              <p>64Kbps mono</p>
            </div>
          </div>
        </div>
        <div className="comparison-column">
          <div className="card">
            <span className="versus">VS</span>
          </div>
          <div className="card">
            <Camera />
          </div>
          <div className="card">
            <VideoRecorder />
          </div>
          <div className="card">
            <Television />
          </div>
          <div className="card">
            <AudioPlayer />
          </div>
        </div>
        <div className="comparison-column">
          <div className="card">
            <h2>
              1<span>TB</span> <span>Plan</span>
            </h2>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>8 MP Camera</h4>
            </div>
            <div className="card-body">
              <h5>357,000 Pictures</h5>
              <p>2.4MB JPG 100%</p>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>HD Video</h4>
            </div>
            <div className="card-body">
              <h5>277 minutes</h5>
              <p>720p Videos</p>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>4K Video</h4>
            </div>
            <div className="card-body">
              <h5>44 hours</h5>
              <p>480p Videos</p>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>M4A Audio</h4>
            </div>
            <div className="card-body">
              <h5>
                3.8 years
                <br />
                Voice Recording
              </h5>
              <p>64Kbps mono</p>
            </div>
          </div>
        </div>
      </div>
      <div className="comparison comparison-mobile">
        <div className="comparison-column">
          <div className="card">
            <h2>
              2<span>GB</span>
            </h2>
            <span className="versus">VS</span>
            <h2>
              1<span>TB</span>
            </h2>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>
                8 MP Camera
                <span>2.4MB JPG 100%</span>
              </h4>
            </div>
            <div className="card-body">
              <h5>
                714
                <br />
                Pictures
              </h5>
              <h5>
                333,123
                <br />
                Pictures
              </h5>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>
                HD Video
                <span>720p Videos</span>
              </h4>
            </div>
            <div className="card-body">
              <h5>
                33
                <br />
                Minutes
              </h5>
              <h5>
                277
                <br />
                Minutes
              </h5>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>
                4K Video
                <span>480p Videos</span>
              </h4>
            </div>
            <div className="card-body">
              <h5>
                5<br />
                Minutes
              </h5>
              <h5>
                44
                <br />
                Hours
              </h5>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <h4>
                M4A Audio
                <span>64Kbps mono</span>
              </h4>
            </div>
            <div className="card-body">
              <h5>
                66 hours
                <br />
                Voice Recording
              </h5>
              <h5>
                3.8 years
                <br />
                Voice Recording
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comparison;
