import React from 'react';
import { clsx } from '../lib/helpers';

const Card: React.FC<{
  accent?: 'primary' | 'orange' | 'green' | 'yellow';
  image?: string;
  title: string;
  description: string;
}> = ({ accent = 'primary', image, title, description }) => (
  <div className="card">
    {image && (
      <div className="card-image-wrapper">
        <img className="card-image" src={image} alt="Card Display" />
      </div>
    )}
    <div className="card-content">
      <h4
        className={clsx('card-title', {
          'accent-orange': accent && accent === 'orange',
          'accent-green': accent && accent === 'green',
          'accent-yellow': accent && accent === 'yellow',
        })}
      >
        {title}
      </h4>
      <p className="card-description">{description}</p>
    </div>
  </div>
);

export default React.memo(Card);
