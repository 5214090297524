const DMCAPolicy = () => (
  <div className="dmca">
    <h2>DMCA Policy</h2>

    <div className="description">
      <p>Posted and effective as of: August 17, 2018</p>
      <p>
        We take the intellectual property rights of others seriously and require that our Customers
        and their Authorized Users do the same. The Digital Millennium Copyright Act established a
        process for addressing claims of copyright infringement that we have implemented for Group
        Photo Services. If you own a copyright or have authority to act on behalf of a copyright
        owner and want to report a claim that a third party is infringing that material on or
        through a Group Photo service, please send a notice to our copyright agent that includes all
        of the items below and we will expeditiously take appropriate action:
      </p>
      <ol>
        <li>A description of the copyrighted work that you claim is being infringed;</li>
        <li>
          A description of the material you claim is infringing and that you want removed or access
          to which you want disabled and the URL or other location of that material;
        </li>
        <li>Your address, telephone number, and email address;</li>
        <li>
          The following statement: “I have a good faith belief that the use of the copyrighted
          material I am complaining of is not authorized by the copyright owner, its agent, or the
          law (e.g., as a fair use)”;
        </li>
        <li>
          The following statement: “The information in this notice is accurate and, under penalty of
          perjury, I am the owner, or authorized to act on behalf of the owner, of the copyright or
          of an exclusive right that is allegedly infringed”; and
        </li>
        <li>
          An electronic or physical signature of the owner of the copyright or a person authorized
          to act on the owner's behalf.
        </li>
      </ol>
    </div>

    <div className="description">
      <p>Our copyright agent can be reached as follows:</p>
      <p>
        <strong>By mail:</strong>
        <br />
        Copyright Agent
        <br />
        Group Photo Software, Inc.
        <br />
        50022 South Slope RPO
        <br />
        Burnaby, British Columbia, V5J 5G3
        <br />
        Canada
      </p>
      <p>
        <strong>By phone:</strong> +1-888-504-6393
      </p>
      <p>
        <strong>By email:</strong> support@groupphoto.com
      </p>
      <p></p>
    </div>

    <div className="description">
      <p>
        We may, in appropriate circumstances, disable or terminate the accounts of users who may be
        repeat infringers.
      </p>
      <p>
        This process does not limit our ability to pursue any other remedies we may have to address
        suspected infringement.
      </p>
    </div>
  </div>
);

export default DMCAPolicy;
