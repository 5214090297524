import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/analytics';
// import 'firebase/app-check';
import config from './configs';

const app = firebase.initializeApp(config.firebase);

// firebase.appCheck().activate(config.appCheckSiteKey, true);

// console.log(`>> ON`)

if (window.location.hostname === 'localhost') {
  app.functions().useEmulator('localhost', 5001);
} else {
  app.analytics();
}

export default app;
