import React from 'react';

import HomepageBanner from '../assets/images/homepage/banner-homepage.png';

//marketing - banner
const Banner: React.FC<{
  title?: string;
  description?: string;
  buttonURL?: string;
  video?: string;
}> = ({ title, description, buttonURL, video }) => (
  <div className="section-banner">
    <div className="marketing-container">
      <h1 className="title">{title}</h1>
      <p className="description">{description}</p>
      <div className="banner-image-container">
        <img src={HomepageBanner} alt="Banner" />
      </div>
    </div>
  </div>
);

export default React.memo(Banner);
