import React from 'react';
import { PageComponent } from '../lib/interfaces';
import Header from '../components/Header';
import Banner from '../components/Banner';
import HowItWorks from '../components/HowItWorks';
import CTA from '../components/CTA';
import Footer from '../components/Footer';
import Card from '../components/Card';

import CardBackup from '../assets/images/homepage/card-automatic-backup.jpg';
import CardPhotoViewing from '../assets/images/homepage/card-photo-viewing.jpg';
import CardOriginalQuality from '../assets/images/homepage/card-original-quality.jpg';
import CardShareMore from '../assets/images/homepage/card-share-more.jpg';

const HowItWorksContent = [
  {
    icon: 'users',
    title: 'Friends and Family',
    description: 'Keep your memories safe and enjoy life.',
    buttonURL: '',
    image: require('../assets/images/logo192.png'),
    imageName: 'sample image',
  },
  {
    icon: 'user-camera',
    title: 'Enthusiasts and Professionals',
    description: 'Get unlimited, safe storage for every pixel you upload.',
    buttonURL: '',
    image: require('../assets/images/logo192.png'),
    imageName: 'sample image',
  },
];

const Homepage: React.FC<PageComponent> = () => {
  return (
    <>
      <div className="intro">
        <Header />
        <Banner
          title="Private Social Photography and Backup"
          description="Group Photo is a better way to backup and share your photos and videos. We protect your privacy, maintain the original quality of your media, and make it easy to share with the people who matter most to you."
        />
      </div>
      <div className="features">
        <Card
          accent="primary"
          image={CardBackup}
          title="Automatic backup"
          description="Never run out of space on your phone. Group Photo automatically backs up all your photos from smartphones, computers, cameras and hard drives."
        />
        <Card
          accent="orange"
          image={CardPhotoViewing}
          title="Best Photo Viewing Experience"
          description="See all your photos on one screen."
        />
        <Card
          accent="green"
          image={CardOriginalQuality}
          title="Original Quality"
          description="Whether it's a 50 megapixel photo or a 4K video, backup your high resolution content as it is! Enjoy your photos and videos in their original quality and download them anytime."
        />
        <Card
          accent="yellow"
          image={CardShareMore}
          title="Share more photos with fewer people"
          description="Share photos in one click with only the people you choose."
        />
      </div>
      <HowItWorks data={HowItWorksContent} />
      {/* <CardListing data={CardListingContent} /> */}
      <CTA />
      <Footer />
    </>
  );
};

export default Homepage;
