import { Link } from 'react-router-dom';
import * as ROUTES from '../../routes';

const PrivacyPolicy = () => (
  <div className="privacy">
    <h2>Privacy Policy</h2>

    <div className="description">
      <p>Posted and effective as of: August 17, 2018</p>
      <p>
        For purposes of this Agreement, “Site” refers to the Company’s website, which can be
        accessed at www.groupphoto.com. “App” refers to the Company’s mobile app, which can be
        accessed at https://groupphoto.com/apps. “Service” refers to the Company’s services accessed
        via the Site or App, in which users can:
      </p>
      <ol type="a">
        <li>pool media individually or as a group,</li>
        <li>upload and backup photos, videos, and audio,</li>
        <li>view, rate, and download photos, videos, and audio.</li>
      </ol>
      <p>
        The terms “we,” “us,” and “our” refer to the Company. “You” and “your” refers to you, as a
        user of our Site or our Service. By accessing our Site or our Service, you accept our
        Privacy Policy and Terms of Use (found here: https://groupphoto.com/legal/terms-of-service),
        and you consent to our collection, storage, use and disclosure of your Personal Information
        as described in this Privacy Policy.
      </p>
      <p>
        We care about your privacy and want to be transparent about how Group Photo handles your
        data.
      </p>
    </div>

    <div className="description">
      <h5>1. What personal data do we collect from you?</h5>
      <p>
        We require your name, email address, mobile phone number, ip address, and username to create
        your Group Photo account. For billing and sales tax purposes only, for standard accounts
        only, we require your billing address.
      </p>
    </div>

    <div className="description">
      <h5>2. What personal data do we not collect from you?</h5>
      <p>
        We respect your privacy and we do not collect any “sensitive data” such as race, religion,
        political affiliation, and sexual orientation.
      </p>
    </div>

    <div className="description">
      <h5>3. What we do with your data?</h5>
      <ol type="a">
        <li>We do not sell or license your data to third parties or anyone. Period.</li>
        <li>
          We use your data in the process of providing you, our customer, with a backup and sharing
          service.
        </li>
        <li>
          Your email address will be used for your login information and to send periodic
          notifications of pool and chat activity within your Group Photo account. These
          notifications can be changed at any time by going into settings on your mobile Group Photo
          app.
        </li>
        <li>
          Your mobile phone number will be used to authenticate your account and new login sessions
          using Multi-Factor Authentication (MFA). This is a 6-digit code we send to your phone for
          added security.
        </li>
        <li>
          Your username and name will only be displayed when you invite users to a pool, chat with
          other users, are a member of a pool, or contribute photos, videos or audio to a pool.
        </li>
        <li>
          Your personal data and media is stored on Group Photo’s secured servers and AWS (Amazon
          Web Services). To offer our users the best customer service, we use Zendesk - a
          cloud-based help desk solution used by more than 200,000 organizations worldwide.
        </li>
        <li>
          Your billing information is sent directly to Stripe for processing; Group Photo does not
          receive, process, or store Primary Account Numbers (PANs), CVCs, or CC expiry dates.
        </li>
        <li>
          We use google analytics to track anonymous website traffic for basic marketing purposes.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>4. How we protect your data</h5>
      <p>
        We implement security measures designed to protect your information from unauthorized
        access. Your account is protected by your account password and we urge you to take steps to
        keep your personal information safe by not disclosing your password and by logging out of
        your account after each use. We further protect your information from potential security
        breaches by implementing certain technological security measures including encryption,
        firewalls, secure socket layer technology, and multi-factor authentication (MFA). However,
        these measures do not guarantee that your information will not be accessed, disclosed,
        altered or destroyed by breach of such firewalls and secure server software. By using our
        Service, you acknowledge that you understand and agree to assume these risks.
      </p>
    </div>

    <div className="description">
      <h5>5. Children’s Privacy</h5>
      <p>
        Group Photo is not directed to anyone under the age of 13. We do not knowingly collect or
        solicit information from anyone under the age of 13, or allow anyone under the age of 13 to
        sign up for the Service. In the event that we learn that we have gathered personal
        information from anyone under the age of 13 without the consent of a parent or guardian, we
        will delete that information as soon as possible. If you believe we have collected such
        information, please contact us at support@groupphoto.com.
      </p>
    </div>

    <div className="description">
      <h5>6. Changes to our Privacy Policy</h5>
      <p>
        Group Photo reserves the right to change this policy and our Terms of Service at any time.
        We will notify you of changes to our Privacy Policy by sending a notice to the primary email
        address specified in your account. Significant changes will go into effect 30 days following
        such notification. Please check the website and this privacy page periodically for updates.
      </p>
    </div>

    <div className="description">
      <h5>7. Contact Us</h5>
      <p>
        If you have any questions regarding this Privacy Policy or the practices of this Site, we’d
        love to hear from you. Please <Link to={ROUTES.CONTACT}>contact us</Link> by sending an
        email to support@groupphoto.com.
      </p>
    </div>
  </div>
);

export default PrivacyPolicy;
