const AcceptableUsePolicy = () => (
  <div className="acceptableUsePolicy">
    <h2>Acceptable Use Policy</h2>

    <div className="description">
      <p>Posted and effective as of: August 17, 2018</p>
      <p>
        Group Photo is built on the idea that your most precious memories should be safe, and should
        be shareable with other people according to how you want them shared. We want this to be an
        environment you want to spend time in and feel good about recommending to your own friends
        and family. We also want this to be a place where everyone behaves appropriately and
        respects each other; in fact, we insist on it.
      </p>
      <p>
        Group Photo Software’s Acceptable Use Policy is more than a set of guidelines we request our
        users try to follow – Acceptable Use is part of our Terms of Service, and all our users are
        required to abide by it. Failure to do so may constitute a breach of those Terms. This
        Policy will explain exactly what we consider to be Acceptable Use for anyone making use of
        our website, smartphone apps, desktop apps, or developer features (our “Services”).
      </p>
    </div>

    <div className="description">
      <h5>1. Copyrighted Content</h5>
      <ol type="a">
        <li>
          Please remember that there is a difference between pictures or other media you created
          yourself, and pictures or other media created by someone else. Using our Services to store
          or share material that you did not create or own “rights of use” to may be considered
          copyright infringement, and as described in Section 3 of Group Photo’s Terms of Service,
          there is a process whereby the legal owners of that material may request that we remove it
          from our Services. We understand that the Internet is full of material that people have
          created for the sole purpose of sharing, and it may be difficult to tell the difference
          between things that are OK to include in pools and things that are not. For that reason,
          accounts will be terminated and media deleted only in cases where we, in our absolute and
          sole discretion, believe the terms of this Policy are being intentionally or maliciously
          violated.
        </li>
        <li>
          A good rule of thumb is to only upload media that you produced yourself. Do not upload
          movies, songs, TV shows, or other similar material produced by others, even if you feel
          that you have a license to them. Instead, ask the producer to upload the material to their
          own account and share it with you.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>2. Respectful Behaviour</h5>
      <ol type="a">
        <li>
          Respectful behaviour is the foundation of any successful community. It creates an
          environment of positivity, sharing, courtesy, and kindness. Disrespectful behaviour does
          the opposite, creating environments of negativity, bitterness, and animosity. Any conduct
          considered disrespectful can be reported to Group Photo through its Support channel, where
          claims can be evaluated and actions initiated. For more information on ways you can report
          violations of Acceptable Use, see Section 3 below. Generally speaking, disrespectful
          behaviour falls into the following categories:
          <ol type="i">
            <li>
              <strong>Hate Speech</strong> – Group Photo celebrates inclusiveness, diversity and
              individuality. We all have opinions, beliefs, and values; however, any content that
              targets an individual or group with hate speech based upon differences of age, race,
              ethnicity, nationality, religion, political affiliation, sexual orientation, gender
              identity, disability, or disease is expressly prohibited. There is a strict
              zero-tolerance policy in place and abuses of this Policy may result in immediate
              termination of accounts and permanent loss of data.
            </li>
            <li>
              <strong>Not Safe For Work</strong> – “NSFW” is a term that was coined to identify
              content that would be inappropriate to view in a workplace or office environment.
              Depictions of nudity, violence, drug use, or other illegal acts can be found in many
              other places on the Internet, but they are not welcome on Group Photo. If the media
              you are sharing has value to the greater community – for example, pictures or video
              you have taken which might be considered newsworthy instead of just graphic – it may
              be considered Acceptable. Please remember that Group Photo is intended for all ages to
              enjoy, and for that reason we ask that anything graphic or disturbing be accompanied
              by a warning, or clearly identified as graphic, as to prevent accidental viewing.
            </li>
            <li>
              <strong>Bullying and Harassment</strong> – Everyone is entitled to their opinions, and
              while we support the principles of Free Speech, we believe there is a line separating
              Free Speech from abuse and harassment. Group Photo does not tolerate any form of
              conduct designed to intimidate, threaten or insult another user anywhere in its
              Services. Content that violates this Policy will be removed immediately, and abuse of
              this Policy may result in immediate termination of accounts and permanent loss of
              data.
            </li>
            <li>
              <strong>Self-Injury</strong> – We encourage all our users to be sensitive to, and
              supportive of, those who are struggling with addiction and other behaviour that causes
              self-injury. We ask that our users share content carefully, and accordingly. Any
              content we believe encourages harmful behaviour, makes light of it, or targets people
              suffering from disorders of any kind will be removed immediately. Abuse of this Policy
              may result in immediate termination of accounts and permanent loss of data.
            </li>
          </ol>
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>3. Reporting Violations of Our Acceptable Use Policies</h5>
      <ol type="a">
        <li>
          Group Photo is about sharing memories in a positive environment. Discussion is encouraged,
          but unfortunately debate and disagreement often turn into argument and disrespect. While
          we believe the community is capable of organizing and moderating itself, we also realize
          that a process for reporting violations of our Acceptable Use Policies must be in place.
          Before reporting what you believe to be a violation of one of these Policies, please take
          a moment to consider whether or not the complaint is consistent with what you have read
          here. Remember that your complaint might result in another user having their account
          suspended or terminated altogether. We will do our best to evaluate each complaint we
          receive, and take actions we deem appropriate for each case. Reporting a violation of any
          of our Acceptable Use Policies is easy – just email us at support@groupphoto.com. Try to
          be as detailed as you can, and our Support agents will be happy to work on a resolution.
          All decisions made by Group Photo’s Support team are final, and we ask that you respect
          their time and judgement.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>4. Abuse of our Services</h5>
      <ol type="a">
        <li>
          We consider any deliberate misuse of our Services – including any action designed to
          damage our Services, compromise another person’s experience while using our Services, or
          gain unauthorized access to our Services - to be Abuse.
        </li>
        <li>
          Abuse is rarely accidental, and for that reason our policies here are Strict. Abuse of
          Services may include, but is not limited to, the following:
          <ol type="i">
            <li>
              Any attempt to exploit Group Photo’s Services, via referral system or otherwise, to
              gain additional free- or paid-level accounts,
            </li>
            <li>
              Any unauthorized attempt to gain access to another user’s account or personal
              information,
            </li>
            <li>Any effort to “scrape” or otherwise harvest data from our Services,</li>
            <li>
              Any use of our Services to place unrelated commercial advertisements into
              public-facing Pools,
            </li>
            <li>
              Any use of our Services inconsistent with their intended use, including (without
              limitation) the storage or transmission of unsupported file types, or
            </li>
            <li>
              Any depiction of criminal or illegal activity outside what might be considered
              “newsworthy”.
            </li>
          </ol>
        </li>
        <li>
          Abuse of Services constitutes a breach of Group Photo’s Terms of Service, and will result
          in immediate suspension or termination of accounts.
        </li>
      </ol>
    </div>
  </div>
);

export default AcceptableUsePolicy;
