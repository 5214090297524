import { useState } from 'react';
import { Switch, Route, Redirect, NavLink, Link, useHistory } from 'react-router-dom';
import * as ROUTES from '../../routes';
import { PageComponent } from '../../lib/interfaces';

import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import AcceptableUsePolicy from './AcceptableUsePolicy';
import DMCAPolicy from './DMCAPolicy';
import EULA from './EULA';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import fabIcon from '../../assets/icons/fab-menu.svg'

const Legal: React.FC<PageComponent> = () => {
  // Router
  const history = useHistory()

  // Navigation Links
  const navigation = [
    {
      label: 'Terms of Service',
      link: ROUTES.TERMS_OF_SERVICE
    },
    {
      label: 'Privacy Policy',
      link: ROUTES.PRIVACY_POLICY
    },
    {
      label: 'Acceptable Use Policy',
      link: ROUTES.ACCEPTABLE_USE_POLICY
    },
    {
      label: 'DMCA Policy',
      link: ROUTES.DMCA_POLICY
    },
    {
      label: 'End-User License Agreement',
      link: ROUTES.EULA
    }
  ]

  // Show/Hide mobile navigation menu.
  const [mobileNavMenu, showMobileNavMenu] = useState(false)
  const navMenuBtn = () => {
    if (!mobileNavMenu) {
      showMobileNavMenu(true)
    } else {
      showMobileNavMenu(false)
    }
  }

  // When the user selects a link via mobile nav menu, it will close the menu while navigating.
  const handleLink = (link) => {
    navMenuBtn()
    history?.push(link)
  }
  return <div className="legal">
    <Header />
    {/* Mobile Navigation Menu */}
    <div className="nav-fab">
      <div className="nav-menu" style={{ 'display': mobileNavMenu ? 'block' : 'none' }}>
        <ul>
          {navigation.map((nav, i) => {
            return <li key={`nav-fab-${i}`}>
              <Link to={nav.link} onClick={(e) => handleLink(nav.link)}>{nav.label}</Link>
            </li>
          })}
        </ul>
      </div>
      <button className="fab" onClick={navMenuBtn}>
        <img src={fabIcon} alt="" />
      </button>
    </div>
    <div className="marketing-container">
      <div className="legal-wrapper">
        {/* Desktop Navigation Menu */}
        <div className="legal-navigation">
          <ul>
            {navigation.map((nav, i) => {
              return <li key={`nav-${i}`}>
                <NavLink exact to={nav.link}>{nav.label}</NavLink>
              </li>
            })}
          </ul>
        </div>
        <div className="legal-content">
          <Switch>
            <Redirect exact from={ROUTES.LEGAL} to={ROUTES.TERMS_OF_SERVICE} />
            <Route exact path={ROUTES.TERMS_OF_SERVICE} component={TermsOfService} />
            <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
            <Route exact path={ROUTES.ACCEPTABLE_USE_POLICY} component={AcceptableUsePolicy} />
            <Route exact path={ROUTES.DMCA_POLICY} component={DMCAPolicy} />
            <Route exact path={ROUTES.EULA} component={EULA} />
          </Switch>
        </div>
      </div>
    </div>

    <Footer />
  </div>
};

export default Legal;
