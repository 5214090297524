import { FC } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as ROUTES from './routes';

import Homepage from './pages/Homepage';
import NotFound from './pages/NotFound';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Download from './pages/Download';
import Legal from './pages/Legal';
import FirebaseAnalytics from './components/FirebaseAnalytics'

import ScrollToTop from './components/ScrollToTop';

const App: FC = () => {
  return (
    <Router>
      <FirebaseAnalytics />
      <ScrollToTop />
      <Switch>
        <Route exact path={ROUTES.HOMEPAGE} component={Homepage} />
        <Route exact path={ROUTES.PRICING} component={Pricing} />
        <Route exact path={ROUTES.CONTACT} component={Contact} />
        <Route exact path={ROUTES.DOWNLOADS} component={Download} />
        <Route path={ROUTES.LEGAL} component={Legal} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default App;
