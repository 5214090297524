import { ChangeEvent, FC, FormEvent, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CTA from '../components/CTA';
import app from '../firebase';


const Contact: FC = () => {
    const [loading, setLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [values, setValues] = useState({ name: '', email: '', message: '' });

    const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        setValues((prev) => ({ ...prev, [name]: value }));
    };

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();

            if (loading) return;
            if (!values.email || !values.message || !values.name) return;

            setLoading(true);

            const sendMail = app.functions().httpsCallable('sendMail');
            await sendMail(values);

            setIsSent(true);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };


    if (isSent) {

        return (
            <div className="contact">
                <Header />
                <div className="section-contact">
                    <div className="marketing-container">
                        <h2>Thank you!</h2>
                        <p className="result-text">We'll do our best to get back to you within 24 hours.</p>
                    </div>
                </div>
            </div>
        )

    } else {

        return (
            <div className="contact">
                <Header />
                <div className="section-contact">
                    <div className="marketing-container">
                        <h2>Contact Us</h2>
                        <form className="contact-form" onSubmit={onSubmit}>
                            <div className="form-field">
                                <label htmlFor="name">Name</label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    onChange={onChange}
                                    value={values.name}
                                    disabled={loading}
                                    required
                                    placeholder="Your Name"
                                />
                            </div>
                            <div className="form-field">
                                <label htmlFor="email">Email</label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={onChange}
                                    value={values.email}
                                    disabled={loading}
                                    required
                                    placeholder="Your Email"
                                />
                            </div>
                            <div className="form-field">
                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    rows={15}
                                    onChange={onChange}
                                    value={values.message}
                                    disabled={loading}
                                    required
                                    placeholder="Your Message"
                                />
                            </div>
                            <div>
                                <button className="btn btn-primary" disabled={loading} type="submit">
                                    {loading ? "Sending..." : "Send"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <CTA />
                <Footer />
            </div>
        );

    }


};

export default Contact;
