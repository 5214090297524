import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ChevronDown, ChevronUp, FaqIcon, PlayCircle } from '../Icons';

//marketing - FAQ
const FAQ: React.FC<any> = () => {
  const [accordion, setAccordion] = useState<any[]>([
    {
      key: 'charging',
      open: false,
      title: 'How much are you actually charging me?',
      content: (
        <>
          <p>There are no hidden fees or surprises.</p>
          <p>
            You only pay for the storage of one copy of your original file (even though we store 9
            copies of each of your originals). The same original that appears in two or more
            different pools is not double-billed. Internet transfer is free. The storage of
            thumbnails or downsampled video renders is free. All fees are in US dollars. We're based
            in Vancouver, so taxes are applied for Canadian or British Columbian accounts. Even
            though this has nothing to do with us, please keep note of whether you're connected to
            WIFI or your cellular network. Because, if you're connected to your cellular network
            when backing your files up, you could incur unexpected, additional fees from your
            cellular provider.
          </p>
        </>
      ),
    },
    {
      key: 'plan',
      open: false,
      title: 'What do I get with a Standard Plan?',
      content: (
        <p>
          A ton more space! Upgrade to a Standard Plan for 1 TB(terabyte) of storage across all your
          devices. See what that means in practical terms.
        </p>
      ),
    },
    {
      key: 'terabyte',
      open: false,
      title: 'What if I need more than 1 TB (terabyte)?',
      content: (
        <>
          <p>
            If you use more than a TB, the standard plan will automatically upgrade to the next
            terabyte. This way, you never have to worry about running out of account storage. Also,
            when your phone or tablet is running out of space, we'll email you so you can enable
            offloading for your device. Offloading will remove photos or videos from your phone once
            they're safely backed up to Group Photo. Offloading is disabled by default. With
            offloading and automatically-upgrading account storage, you'll have complete photo and
            video freedom. You'll never have to worry about running out of space on your device or
            your Group Photo account when you're busy taking photos. Please see the pricing
            calculator, above, for specific pricing on additional terabytes.
          </p>
          <p>
            We're still in the process of rigorously testing the Offloading feature. We want to be
            very, very certain that all of your photos are safe before they're removed from your
            device. The Offloading feature will be coming very soon.
          </p>
        </>
      ),
    },
    {
      key: 'advertisement',
      open: false,
      title: 'Are there advertisements with the free account or the standard account?',
      content: (
        <>
          <p>No. Group Photo only charges for storage. We have no ads and never will.</p>
        </>
      ),
    },
    {
      key: 'downgrade',
      open: false,
      title: 'Can I downgrade later?',
      content: <p>Of course. You can downgrade your Standard Plan to a Free Plan at any point.</p>,
    },
    {
      key: 'credit-card',
      open: false,
      title: 'Do I have to use a credit card?',
      content: <p>Yes, we currently only accept credit card payments.</p>,
    },
  ]);

  const handleToggleAccordion = (key: string) => {
    const updatedAccordion = accordion.map((a) => {
      if (a.key === key) a.open = !a.open;
      return a;
    });

    setAccordion(updatedAccordion);
  };

  return (
    <div className="section-faq">
      <div className="marketing-container">
        <FaqIcon />
        <h2>Frequently Asked Questions</h2>

        {accordion.map((a) => (
          <div className="content-item" key={a.key}>
            <label className={a.open ? 'mb pb-0' : ''}>
              <h3>{a.title}</h3>
              {!a.open && <ChevronDown />}
              {a.open && <ChevronUp />}
              <input
                className="visually-hidden"
                type="checkbox"
                onChange={() => handleToggleAccordion(a.key)}
              />
            </label>
            {a.open && <div className="content-item-description">{a.content}</div>}
          </div>
        ))}
        <div className="button-wrapper">
          <Link to={''} className="link">
            <PlayCircle /> See what you can do for free
          </Link>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FAQ);
