const EULA = () => (
  <div className="eula">
    <h2>End-User License Agreement</h2>

    <div className="description">
      <p>Posted and effective as of: August 17, 2018</p>
      <p>
        Please read this End-User License Agreement ("Agreement") carefully before clicking the "I
        Agree" button, downloading or using Group Photo Desktop App ("Application").
      </p>
      <p>
        By clicking the "I Agree" button, downloading or using the Application, you are agreeing to
        be bound by the terms and conditions of this Agreement.
      </p>
      <p>
        If you do not agree to the terms of this Agreement, do not click on the "I Agree" button and
        do not download or use the Application.
      </p>
    </div>

    <div className="description">
      <h5>1. License</h5>
      <p>
        Group Photo grants you a revocable, non-exclusive, non-transferable, limited license to
        download, install and use the Application solely for your personal, non-commercial purposes
        strictly in accordance with the terms of this Agreement.
      </p>
    </div>

    <div className="description">
      <h5>2. Restrictions</h5>
      <p>You agree not to, and you will not permit others to:</p>
      <ol type="a">
        <li>
          license, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or
          otherwise commercially exploit the Application or make the Application available to any
          third party;
        </li>
        <li>
          decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt
          the Application;
        </li>
        <li>
          violate any applicable laws, rules or regulations in connection with Your access or use of
          the Application;
        </li>
        <li>
          remove, alter or obscure any proprietary notice (including any notice of copyright or
          trademark) of Company or its affiliate, partners, suppliers or the licensors of the
          application;
        </li>
        <li>
          use the application for creating a product, service or software that is, directly or
          indirectly, competitive with or in any way substitute for any services, product or
          software offered by the Company;
        </li>
        <li>
          use the Application to send automated queries to any website or to send any unsolicited
          material;
        </li>
        <li>
          use any proprietary information or interfaces of company or other intellectual property of
          Company in the design, development, manufacture, licensing or distribution of any
          applications, accessories or devices for use with the Application.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>3. Modifications to Application</h5>
      <p>
        Group Photo reserves the right to modify, suspend or discontinue, temporarily or
        permanently, the Application or any service to which it connects, with or without notice and
        without liability to you.
      </p>
    </div>

    <div className="description">
      <h5>4. Term and Termination</h5>
      <ol type="a">
        <li>This Agreement shall remain in effect until terminated by you or Group Photo.</li>
        <li>
          Group Photo may, in its sole discretion, at any time and for any or no reason, suspend or
          terminate this Agreement with or without prior notice.
        </li>
        <li>
          This Agreement will terminate immediately, without prior notice from Group Photo, in the
          event that you fail to comply with any provision of this Agreement. You may also terminate
          this Agreement by deleting the Application and all copies thereof from your mobile device
          or from your desktop.
        </li>
        <li>
          Upon termination of this Agreement, you shall cease all use of the Application and delete
          all copies of the Application from your mobile device or from your desktop.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>5. Severability</h5>
      <ol type="a">
        <li>
          If any provision of this Agreement is held to be unenforceable or invalid, such provision
          will be changed and interpreted to accomplish the objectives of such provision to the
          greatest extent possible under applicable law and the remaining provisions will continue
          in full force and effect.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>6. Amendments to this Agreement</h5>
      <ol type="a">
        <li>
          Group Photo grants you a revocable, non-exclusive, non-transferable, limited license to
          download, install and use the Application solely for your personal, non-commercial
          purposes strictly in accordance with the terms of this Agreement.
        </li>
      </ol>
    </div>

    <div className="description">
      <h5>7. Contact Information</h5>
      <p>
        If you have any questions about this Agreement, please contact us at support@groupphoto.com.
      </p>
    </div>
  </div>
);

export default EULA;
