export const clsx = (
  defaultClasses: string = '',
  conditionalClasses: { [x: string]: boolean } = {}
) => {
  if (!Object.keys(conditionalClasses).length) {
    return defaultClasses;
  }

  const addedClassName: string[] = [];
  for (let conditionalKey in conditionalClasses) {
    if (conditionalClasses[conditionalKey]) {
      addedClassName.push(conditionalKey);
    }
  }

  if (!addedClassName.length) {
    return defaultClasses;
  }

  return `${defaultClasses} ${addedClassName.join(' ')}`.trim();
};
