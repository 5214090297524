import React from 'react';
import { ArrowRightWhite } from '../Icons';
import * as ROUTES from '../routes';

//marketing - CTA
const CTA: React.FC<any> = () => (
  <div className="section-cta">
    <div className="marketing-container">
      <h2 className="title">Keep your memories safe and secure.</h2>
      <h2 className="title">All in one place.</h2>
      <a href={ROUTES.APP_REGISTER} className="btn btn-primary" rel="noreferrer">
        Try for free <ArrowRightWhite />
      </a>
      <p className="description">
        We don't sell ads, or invade your privacy.
      </p>
    </div>
  </div>
);

export default React.memo(CTA);
