import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../routes';
import config from '../configs';

import HeaderLogo from '../assets/images/logo_04.svg';
import AppleStore from '../assets/images/astore.png';
import GooglePlay from '../assets/images/gplay.png';
import MacOS from '../assets/images/macos.png';
import Windows from '../assets/images/windows.png';

//marketing - Footer
const Footer: React.FC<any> = () => (
  <footer>
    <div className="marketing-container">
      <div className="content">
        <div className="details-wrapper">
          <Link to="/">
            <img src={HeaderLogo} className="gp-logo" alt="groupphoto logo" />
          </Link>

          <p className="copyright">
            &copy; {new Date().getFullYear()} Group Photo, Inc.&nbsp;
            <a
              href="https://groupphoto.com/legal/terms-of-service"
              className="terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms &amp; Privacy
            </a>
          </p>
          {/* TODO: Add <a></a> tags below for mobile app store links. */}
          <div className="dl-logo-wrapper">
            <a href={config.downloads.ios ?? ""} className="dl-logo">
              <img src={AppleStore} alt="" />
            </a>
            <a href={config.downloads.android} className="dl-logo">
              <img src={GooglePlay} alt="" />
            </a>
          </div>
          <div className="dl-logo-wrapper">
            <a href={config.downloads.macos} className="dl-logo">
              <img src={MacOS} alt="" />
            </a>
            <a href={config.downloads.windows} className="dl-logo">
              <img src={Windows} alt="" />
            </a>
          </div>
        </div>

        <div className="link-wrapper">
          <div className="link-listing"></div>
          <div className="link-listing">
            <h4 className="link-header">Product</h4>
            <Link to={ROUTES.HOMEPAGE} className="link-item">
              Overview
            </Link>
            <Link to={ROUTES.PRICING} className="link-item">
              Pricing
            </Link>
            <Link to={ROUTES.LEGAL} className="link-item">
              Legal
            </Link>
            {/* <Link to={ROUTES.FAMILIES} className="link-item">
              Personal use
            </Link>
            <Link to={ROUTES.PROFESSIONALS} className="link-item">
              Professionals
            </Link> */}
          </div>
          {/* <div className="link-listing">
            <h4 className="link-header">Team</h4>
            <Link to="" className="link-item">
              Our Story
            </Link>
            <Link to="" className="link-item">
              Careers
            </Link>
            <Link to="" className="link-item">
              Investors
            </Link>
          </div> */}
          <div className="link-listing">
            <h4 className="link-header">Help &amp; Contact</h4>
            <Link to={ROUTES.CONTACT} className="link-item">
              Email us
            </Link>
            <a
              href="https://twitter.com/WeAreGroupPhoto"
              className="link-item"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default React.memo(Footer);
