const config = {
  firebase: {
    apiKey: 'AIzaSyANzoFcz9vSqvC4Mysvyho9MhQvYVVrlIs',
    authDomain: 'group-photo-dev.firebaseapp.com',
    databaseURL: 'https://group-photo-dev.firebaseio.com',
    projectId: 'group-photo-dev',
    storageBucket: 'group-photo-dev.appspot.com',

    messagingSenderId: '601223379971',
    appId: '1:601223379971:web:f5db7f9b0141ef14ed2236',
    measurementId: 'G-CPQ8LW9E94',
  },
  domains: {
    app: 'app.gpdev.co',
    marketing: 'gpdev.co',
  },
  stripe: {
    publishable_key:
      'pk_test_51IgQJrIgfIijT8ehcreABs0btymRof3ZUF8QG638hWN2iiQQApwtJOJ1L1WkLJKzsNZvk82xirEJnU6nfkMvIojt00hPgjplHx',
  },
  appCheckSiteKey: '6LdQF6gcAAAAABTqLXACnpSq6VNn95QGEENr3rFe',
  downloads: {
    windows: 'https://groupphoto.com/download/GroupPhotoSetup.dev.exe',
    macos: 'https://groupphoto.com/download/GroupPhoto.dev.dmg',
    android: 'https://groupphoto.com/download/GroupPhoto-dev.apk',
    ios: ""
  },
};

export default config;
