import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/functions';


// This allows pageloads to be tracked in GA

export default function FirebaseAnalytics() {

    const location = useLocation()
    const analytics = firebase.analytics()

    useEffect(() => {

        const page_path = location.pathname + location.search

        analytics.setCurrentScreen(page_path)

        analytics.logEvent("marketing_page_view", { page_path })

    }, [location, analytics]);

    return null;

}