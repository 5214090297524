import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { clsx } from '../lib/helpers';
import * as ROUTES from '../routes';

import HeaderLogo from '../assets/images/logo_04.svg';
import { ArrowRightWhite, BarsFilled, TimesFilled, UserOutline } from '../Icons';

//marketing - header
const Header: React.FC = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <header className={showMenu ? 'show-menu' : ''}>
      <div className="marketing-container">
        <div className="header-content">
          <div className="logo-holder">
            <Link to="/">
              <img src={HeaderLogo} className="image" alt="groupphoto logo" />
            </Link>
          </div>

          {showMenu ? (
            <button className="btn btn-menu" onClick={() => setShowMenu(false)}>
              <TimesFilled />
            </button>
          ) : (
            <button className="btn btn-menu" onClick={() => setShowMenu(true)}>
              <BarsFilled />
            </button>
          )}

          <div className={clsx('menu', { show: showMenu === true })}>
            <div className="link-list-holder">
              <div className="link-item">
                <NavLink exact to={ROUTES.PRICING} className="link">
                  Pricing
                </NavLink>
              </div>
              <div className="link-item">
                <NavLink exact to={ROUTES.DOWNLOADS} className="link">
                  Download
                </NavLink>
              </div>
              <div className="link-item">
                <NavLink exact to={ROUTES.CONTACT} className="link">
                  Contact
                </NavLink>
              </div>
            </div>
            <div className="button-holder" onClick={() => setShowMenu(false)}>
              <a href={ROUTES.APP_LOGIN} className="btn btn-blue-outline btn-login">
                <UserOutline />
                Login
              </a>
              <a href={ROUTES.APP_REGISTER} className="btn btn-primary btn-try">
                Try for free
                <ArrowRightWhite />
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
