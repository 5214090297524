import { PayPer } from './interfaces';

export const prices: PayPer[] = [
  {
    mo: { yr: '', plan: 'monthly_1', mo: '10.99' },
    yr: { yr: '99', plan: 'yearly_1', mo: '8.25' },
  },
  {
    mo: { yr: '', plan: 'monthly_2', mo: '31.98' },
    yr: { yr: '298', plan: 'yearly_2', mo: '24.83' },
  },
  {
    mo: { yr: '', plan: 'monthly_3', mo: '52.97' },
    yr: { yr: '497', plan: 'yearly_3', mo: '41.42' },
  },
  {
    mo: { yr: '', plan: 'monthly_4', mo: '73.96' },
    yr: { yr: '696', plan: 'yearly_4', mo: '58.00' },
  },
  {
    mo: { yr: '', plan: 'monthly_5', mo: '94.95' },
    yr: { yr: '895', plan: 'yearly_5', mo: '74.58' },
  },
  {
    mo: { yr: '', plan: 'monthly_6', mo: '115.94' },
    yr: { yr: '1094', plan: 'yearly_6', mo: '91.17' },
  },
  {
    mo: { yr: '', plan: 'monthly_7', mo: '136.93' },
    yr: { yr: '1293', plan: 'yearly_7', mo: '107.75' },
  },
  {
    mo: { yr: '', plan: 'monthly_8', mo: '157.92' },
    yr: { yr: '1492', plan: 'yearly_8', mo: '124.33' },
  },
  {
    mo: { yr: '', plan: 'monthly_9', mo: '178.91' },
    yr: { yr: '1691', plan: 'yearly_9', mo: '140.92' },
  },
  {
    mo: { yr: '', plan: 'monthly_10', mo: '199.90' },
    yr: { yr: '1890', plan: 'yearly_10', mo: '157.50' },
  },
].map((partial: Omit<PayPer, 'label' | 'type'>, index) => {
  partial.yr.only = partial.yr.yr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  partial.mo.yr = (Number(partial.mo.mo) * 12).toFixed(2);
  partial.mo.only = partial.yr.yr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  partial.mo.save = (Number(partial.mo.yr) - Number(partial.yr.yr)).toFixed(2);
  return { ...partial, label: `${index + 1} TB Storage`, type: `${index + 1}TB` };
});

export const ANALYTIC_EVENTS = {
  DOWNLOADED_DESKTOP_APP: 'downloaded_desktop_app',
  CLICKED_APPSTORE_LINK: 'clicked_appstore_link',
  CLICKED_PLAYSTORE_LINK: 'clicked_playstore_link',
  CREATED_POOL: 'created_pool',
  INVITED_CONTACT_TO_POOL: 'invited_contact_to_pool',
  JOINED_POOL_AS_INVITEE: 'joined_pool_as_invitee',
  PURCHASED_SUBSCRIPTION: 'purchased_subscription',
  RATED_ASSET: 'rated_asset',
};
