import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CTA from '../components/CTA';

const NotFound: React.FC = () => (
  <div className="not-found">
    <Header />
    <div className="section-not-found">
      <div className="marketing-container">
        <h1>404</h1>
        <p>The page you were looking for was not found</p>
      </div>
    </div>
    <CTA />
    <Footer />
  </div>
);

export default NotFound;
