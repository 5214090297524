import config from './configs';

export const APP = `https://${config.domains.app}`;
export const APP_REGISTER = `https://${config.domains.app}/register`;
export const APP_LOGIN = `https://${config.domains.app}/login`;

export const HOMEPAGE = '/';
export const FAMILIES = '/families';
export const PROFESSIONALS = '/professionals';
export const CONTACT = '/contact';
export const PRIVACY = '/privacy';
export const PRICING = '/pricing';
export const NOT_FOUND = '/404';
export const DOWNLOADS = '/download';

export const LEGAL = '/legal';
export const TERMS_OF_SERVICE = '/legal/terms-of-service';
export const PRIVACY_POLICY = '/legal/privacy-policy';
export const ACCEPTABLE_USE_POLICY = '/legal/acceptable-use-policy';
export const DMCA_POLICY = '/legal/dmca-policy';
export const EULA = '/legal/end-user-license-agreement';
