import React, { FC } from 'react';
import app from '../firebase';
import config from '../configs';

import Header from '../components/Header';
import Footer from '../components/Footer';
import CTA from '../components/CTA';

import AppleStore from '../assets/images/astore.png';
import GooglePlay from '../assets/images/gplay.png';
import MacOS from '../assets/images/macos.png';
import Windows from '../assets/images/windows.png';
import { ANALYTIC_EVENTS } from '../lib/constants';

const Downloads: FC = () => {
  const onLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const href = event.currentTarget.href;

    if (href === config.downloads.ios) {
      app.analytics().logEvent(ANALYTIC_EVENTS.CLICKED_APPSTORE_LINK);
    } else if (href === config.downloads.android) {
      app.analytics().logEvent(ANALYTIC_EVENTS.CLICKED_PLAYSTORE_LINK);
    } else if (href === config.downloads.macos || href === config.downloads.windows) {
      app.analytics().logEvent(ANALYTIC_EVENTS.DOWNLOADED_DESKTOP_APP);
    }

    return;
  };

  return (
    <div className="downloads">
      <Header />
      <div className="section-downloads">
        <div className="marketing-container">
          <h2>Available in:</h2>

          <div className="downloads-wrapper">
            <a href={config.downloads.ios ?? ''} onClick={onLinkClick}>
              <img src={AppleStore} alt="Apple Store" />
            </a>
            <a href={config.downloads.android} onClick={onLinkClick}>
              <img src={GooglePlay} alt="Google Play" />
            </a>
            <a href={config.downloads.macos} onClick={onLinkClick}>
              <img src={MacOS} alt="MacOS" />
            </a>
            <a href={config.downloads.windows} onClick={onLinkClick}>
              <img src={Windows} alt="Windows" />
            </a>
          </div>
        </div>
      </div>
      <CTA />
      <Footer />
    </div>
  );
};

export default Downloads;
