import { ChangeEvent, FC, useMemo, useState } from 'react';
import { PageComponent, Plan } from '../lib/interfaces';
import Header from '../components/Header';
import Comparison from '../components/Comparison';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import { clsx } from '../lib/helpers';
import { prices } from '../lib/constants';
import * as ROUTES from '../routes';

import { CheckCircle, CheckCircleWhite, ChevronDown, ChevronUp } from '../Icons';

//marketing - pricing
const Pricing: FC<PageComponent> = () => {
  const [display, setDisplay] = useState(false);
  const [activeCycle, setActiveCycle] = useState('yr');
  const [storageType, setStorageType] = useState('1TB');

  const onCycleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setActiveCycle(event.target.value);
  };

  const onStorageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setStorageType(event.target.value);
  };

  const selectedStorageType = useMemo(
    () => prices.find((price) => price.type === storageType) || prices[0],
    [storageType]
  );
  const price: Plan = useMemo(
    () => selectedStorageType[activeCycle],
    [activeCycle, selectedStorageType]
  );

  return (
    <div className="pricing">
      <Header />
      <div className="section-pricing">
        <div className="marketing-container">
          <h1>Group Photo Pricing</h1>
          <div className="select-billing-wrapper">
            <div className="select-billing-content">
              <label className={activeCycle === 'mo' ? 'active' : ''}>
                Monthly
                <input
                  className="visually-hidden"
                  type="radio"
                  name="payment_cycle"
                  value="mo"
                  checked={activeCycle === 'mo'}
                  onChange={onCycleChange}
                />
              </label>
              <label className={activeCycle === 'yr' ? 'active' : ''}>
                Yearly
                <span>Save 20%</span>
                <input
                  className="visually-hidden"
                  type="radio"
                  name="payment_cycle"
                  value="yr"
                  checked={activeCycle === 'yr'}
                  onChange={onCycleChange}
                />
              </label>
            </div>
          </div>

          <div className="cards-wrapper">
            <div className="cards">
              <div className="card-item first-card">
                <p className="type">Free</p>
                <p className="price">$0</p>

                <ul>
                  <li>
                    <CheckCircle />2 GB Storage
                  </li>
                  <li>
                    <CheckCircle />
                    Full Functionality
                  </li>
                  <li>
                    <CheckCircle />
                    No Ads
                  </li>
                </ul>

                <div className="button-holder">
                  <a
                    href={ROUTES.APP_REGISTER}
                    className="btn btn-blue-outline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Sign Up for FREE
                  </a>
                </div>
              </div>

              <div className="card-item second-card">
                <p className="type">Standard</p>
                {price && (
                  <p className="price">
                    ${price.mo} <span>/ mo</span>
                    <small
                      style={{
                        display: 'block',
                        opacity: 0.7,
                        lineHeight: '0px',
                        fontStyle: 'italic',
                      }}
                    >
                      {activeCycle === 'mo' && <span>save ${price.save} switching to yearly</span>}
                      {activeCycle === 'yr' && <span>(${price.only} annually)</span>}
                    </small>
                  </p>
                )}

                <select value={storageType} onChange={onStorageChange}>
                  {prices.map((p) => (
                    <option key={p.label} value={p.type}>
                      {p.label}
                    </option>
                  ))}
                </select>
                <ul>
                  <li>
                    <CheckCircleWhite />
                    Full Functionality
                  </li>
                  <li>
                    <CheckCircleWhite />
                    No Ads
                  </li>
                </ul>
                <div className="button-holder">
                  <a
                    href={ROUTES.APP_REGISTER}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-comparison">
        <div className="marketing-container">
          <div
            className={clsx('trigger', {
              active: display,
            })}
            onClick={() => setDisplay(!display)}
          >
            <p>
              See what can you with <strong>2 GB</strong> vs <strong>1 TB</strong>
            </p>
            {!display && <ChevronDown />}
            {display && <ChevronUp />}
          </div>
          {display && <Comparison />}
        </div>
      </div>
      <FAQ />
      <Footer />
    </div>
  );
};

export default Pricing;
